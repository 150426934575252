.send-content {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  .center {
    .img-box {
      width: 150px;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      margin: auto;
      &::after {
        content: "";
        display: block;
        background: #ffffff;
        height: 100%;
        position: absolute;
        box-shadow: 0 0 12px 6px #fff;
        top: 0;
        left: 0px;
        transform: rotate(30deg);
        animation: 2s move infinite linear;
      }
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .title {
      margin: 0 0 50px;
      font-size: 16px;
      text-align: center;
    }
    .circle {
      width: 30px;
      // height: 30px;
      margin: auto;
      animation: 1s loadingCircle infinite linear;
    }
  }
  .foot {
    position: absolute;
    bottom: 10px;
    text-align: center;
    padding: 0 50px;
    font-size: 13px;
  }
}

.code-page {
  padding: 10px 20px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.105);
  }
  .h2 {
    margin: 15px 0;
    font-weight: 500;
    font-size: 16px;
  }
  .p {
    margin: 15px 0;
    font-size: 14px;
  }
  .row1 {
    margin: 40px 0 0;
    .row1-title {
      margin: 0 0 5px;
      font-size: 14px;
    }
    input {
      width: 100%;
      height: 42px;
      border: 1px solid #808080;
      padding: 0 15px;
      &:focus {
        outline: none;
      }
    }
    .row1-btn {
      text-align: center;
      background-color: rgb(29, 98, 161);
      color: #fff;
      width: 100%;
      padding: 10px;
      border-radius: 4px;
    }
  }
  .span{
    color: rgb(250, 12, 12);
    font-size: 15px;
    line-height: 20px;
    margin: 10px 0;
    font-weight: 600;
  }
}

@keyframes move {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}

@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}
