.pop-box {
  display: none;
  position: fixed;
  top: 20px;
  height: auto;
  width: 98%;
  padding: 10px 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff6f8;
  border: 1px solid #f77167;
  animation-delay: calc(-1s * var(--i));
  z-index: 20;
  border-radius: 4px;
  color: #f77167;
  h4 {
    font-size: 14px;
    display: flex;
    align-items: center;
    line-height: 20px;
    p{
      padding: 0 10px;
    }
  }
}

.pop-show {
  display: block !important;
}

@keyframes animate1 {
  0% {
    transform: translateY(-6.25rem);
  }
  50% {
    transform: translateY(65px);
  }
  75% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(65px);
  }
}

