.paypal-page {
  padding: 20px;
  color: #001435;
  width: 500px;
  margin: 100px auto;
  border: 1px solid #999;
  .header {
    display: flex;
    justify-content: center;
    img {
      width: 35px;
    }
  }
  .title {
    margin: 20px 0 0;
    text-align: center;
    font-size: 22px;
    font-weight: 600;
  }
  .key {
    margin: 20px 0 0;
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    .span{
      padding: 0 20px;
      color: #0551b5;
      font-weight: 500;
    }
  }
  .sec-title {
    margin: 20px 0 0;
    text-align: center;
    font-weight: 500;
    line-height: 20px;
  }
  input {
    margin: 20px 0 0;
    width: 100%;
    padding: 15px 10px;
    border-radius: 5px;
    border: 1px solid #a5a5a5;
    &:focus {
      outline: 2px solid #5299f7;
    }
  }
  .error-tip{
    margin: 10px 0 0;
    color: red;
    font-size: 14px;
  }
  .next-btn {
    margin: 20px 0 0;
    padding: 12px 32px;
    border-radius: 50px;
    background-color: #0551b5;
    color: #fff;
    text-align: center;
    font-weight: 600;
    &:hover {
      opacity: 0.9;
    }
  }
  .line {
    margin: 40px 0 0;
    border-bottom: 1px solid #e2e2e2;
    position: relative;
    .txt {
      background-color: #fff;
      padding: 10px;
      position: absolute;
      top: -20px;
      left: 50%;
      transform: translateX(-50%);
      color: #8a8a8a;
    }
  }
  .sign-btn {
    margin: 40px 0 0;
    padding: 12px 32px;
    border-radius: 50px;
    border: 2px solid;
    text-align: center;
    font-weight: 600;
    &:hover {
      opacity: 0.9;
    }
  }
}



@media screen and (max-width: 500px) {
  .paypal-page {
    margin: 0;
    border: 0;
    width: 100%;
  }
}
