.loading-mask {
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 111;
  .loading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .load-gif{
      width: 60px;
      height: 60px;
    }
    .pay-txt{
      margin: 20px 0 0;
      font-weight: 500;
      font-size: 14px;
      &::after {
        content: "";
        display: block;
        background: #ffffff;
        height: 100%;
        position: absolute;
        box-shadow: 0 0 12px 6px #fff;
        top: 0;
        left: 0px;
        transform: rotate(30deg);
        animation: 1.5s move infinite linear;
      }
    }
  }
}

@keyframes move {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}


@keyframes rotateAnimate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.show {
  display: block !important;
}

@media screen and (max-width: 540px) {
  .loading-mask {
    .loading {
      img {
        width: 100px;
        height: 100px;
      }
    }
  }
}
